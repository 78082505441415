import styled from "astroturf/index"
import React, { memo } from "react"
import ratingAutsorsersImage from "../../images/rating-autsorsers.svg"
import ratingDevelopersImage from "../../images/rating-developers.svg"
import { BigWhiteButton } from "../../ui/Buttons"
import video from "../../video/test.mp4"
import { WidthContainer } from "../../ui/WidthContainer"
import ym from "react-yandex-metrika"

export const HeaderVideo = memo(() => {
  const onClickSubmitApplication = React.useCallback(() => {
    ym("reachGoal", "bigButtonSubmitApp")
    const writeToUs = document.getElementById("writeToUs")
    if (writeToUs) {
      writeToUs.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <>
      <Container id="home">
        <Video muted playsInline autoPlay loop>
          <source type="video/mp4" src={video} />
        </Video>

        <VideoOverlay />

        <TextContainer>
          <WidthContainer>
            <Content>
              <Title>Solid team of developers to strengthen your product</Title>
              <SubTitle>We design, develop and support innovative Mobile, Web and Cross-platform IT solutions</SubTitle>
              <BigWhiteButton onClick={onClickSubmitApplication}>Submit request</BigWhiteButton>
            </Content>
          </WidthContainer>

          <WidthContainer>
            <ContentSecondPart>
              <RatingsRow>
                <RatingDevelopersImage src={ratingDevelopersImage} alt={"Mobile Activity Rating"} />
                <RatingAutsorsersImage src={ratingAutsorsersImage} alt={"Rating of outsourcers"} />
              </RatingsRow>
            </ContentSecondPart>
          </WidthContainer>
        </TextContainer>
      </Container>
    </>
  )
})

const Container = styled.div`
  display: flex;
  background-color: #102037;
  overflow: hidden;
  justify-content: center;
  position: relative;

  min-height: calc(100vh - 168px);
  @media screen and (max-width: 1199px) {
    min-height: auto;
  }

  @media screen and (max-width: 600px) {
    min-height: calc(100vh - 92px);
  }
`

const Video = styled.video`
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`

const VideoOverlay = styled.video`
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: 2;
  background: #102037;
  opacity: 0.7;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContentSecondPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TextContainer = styled.div`
  padding-top: 160px;
  padding-bottom: 51px;
  z-index: 2;

  @media screen and (max-width: 1199px) {
    padding-top: 92px;
    padding-bottom: 50px;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 64px;
  line-height: 77px;
  text-align: center;
  color: #ffffff;
  margin: 0;

  @media (max-height: 800px) {
    font-size: 50px;
    line-height: 50px;
  }

  @media screen and (max-width: 1199px) {
    font-size: 28px;
    line-height: 34px;
  }
`

const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  margin: 40px 0 0;
  color: #ffffff;

  @media (max-height: 800px) {
    font-size: 24px;
    line-height: 24px;
  }

  @media screen and (max-width: 1199px) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
  }
`

const RatingsRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 69px;

  @media (max-height: 800px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 1199px) {
    margin-top: 50px;
  }
`

const RatingDevelopersImage = styled.img`
  width: 163px;
  @media screen and (max-width: 1199px) {
    width: 148px;
  }
`

const RatingAutsorsersImage = styled.img`
  width: 169px;
  margin-left: 110px;
  @media screen and (max-width: 1199px) {
    width: 147px;
    margin-left: 30px;
  }
  @media screen and (max-width: 374px) {
    margin-left: 10px;
  }
`

// const SliderConatainer = styled.div`
//   margin-top: 45px;
//   @media screen and (min-width: 1200px) {
//     display: none;
//   }
// `

// const Company = styled.a``

// const CompanyLogo = styled.img`
//   min-width: 145px;
//   height: 32px;
//   object-fit: contain;
// `
