import React from "react"
import imgPDF from "./iconPDF.svg"
import iconDownload from "./iconDownload.svg"
import "./stylePopup.scss"
import ym from "react-yandex-metrika"

export const Popup = () => {
  React.useEffect(() => {
    setTimeout(() => {
      document.getElementById("container_vidjet").style.bottom = 0
    }, 15000)

    setTimeout(() => {
      document.getElementById("container_vidjet").style.bottom = "-38px"
    }, 25000)
  }, [])

  return (
    <a
      id={"container_vidjet"}
      className={"container_vidjet"}
      href={"https://dextechnology.com/presentation.pdf"}
      download={true}
      target={"_blank"}
      rel={"noopener noreferrer"}
      onClick={ym("reachGoal", "clickLoadPresentation")}
    >
      <img src={imgPDF} alt={"iconPDF"} />
      <div>
        <div className={"title_vidjet"}>Want to know more?</div>
        <div className={"description_vidjet"}>
          Download presentation
          <img alt={"iconDownload"} src={iconDownload} />
        </div>
      </div>
    </a>
  )
}
